import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Loader from 'components/Loader';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import AuthGuard from 'utils/route-guard/AuthGuard';

import useConfig from 'hooks/useConfig';


import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';

import { MenuOrientation } from 'config';

const DashboardLayout = () => {
  const theme = useTheme();
  const { menuMasterLoading } = useGetMenuMaster();
  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  useEffect(() => {
    if (!miniDrawer) {
      handlerDrawerOpen(!matchDownXL);
    }
  }, [matchDownXL, miniDrawer]);
  

  if (menuMasterLoading) return <Loader />;

  return (
    <AuthGuard>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />
        {!isHorizontal ? <Drawer /> : <HorizontalBar />}

        <Box
          component="main"
          sx={{
            width: 'calc(100% - 10px)',
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            pb: '5%'  // Padding bottom set to 5%
          }}
        >
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
          <Breadcrumbs />
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </AuthGuard>
  );
};

export default DashboardLayout;
