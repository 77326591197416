import axios from 'axios';
import CryptoJS from 'crypto-js';

const BASE_URL = process.env.REACT_APP_API_URL;
const HMAC_SECRET = process.env.REACT_APP_HMAC_SECRET;
const DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE;

const axiosServices = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // 10 seconds timeout
  headers: { 'Content-Type': 'application/json' },
});


axiosServices.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('accessToken'); // Retrieve JWT token from cookies
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Generate HMAC for request signature
export const generateHMAC = (method, url, body = '{}') => {
  const normalizedURL = `${BASE_URL.replace(/\/$/, '')}${url}`;
  const payload = `${method.toUpperCase()}${normalizedURL}${body}`;
  return CryptoJS.HmacSHA256(payload, HMAC_SECRET).toString(CryptoJS.enc.Hex);
};

// Log debug information if enabled
export const logDebug = (...args) => {
  if (DEBUG_MODE === 'true') console.log(...args);
};

// Get a cookie by name
export const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

// Set a cookie
export const setCookie = (name, value, attributes = {}) => {
  const cookieAttributes = {
    path: '/',
    ...attributes,
  };

  if (cookieAttributes.expires instanceof Date) {
    cookieAttributes.expires = cookieAttributes.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let key in cookieAttributes) {
    updatedCookie += "; " + key;
    const value = cookieAttributes[key];
    if (value !== true) {
      updatedCookie += "=" + value;
    }
  }

  document.cookie = updatedCookie;
};

export const clearTokens = () => {
  // Using setCookie utility to clear tokens
  setCookie('accessToken', '', { path: '/', expires: new Date(0) });
  setCookie('refreshToken', '', { path: '/', expires: new Date(0) });
  setCookie('user', '', { path: '/', expires: new Date(0) });
};

// Fetcher utility functions for GET, POST, PUT, DELETE requests



export const fetcher = async (args, options = { hmac: false }) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  
  // Generate HMAC if enabled
  const headers = options.hmac
    ? { 
        'X-Signature': generateHMAC('GET', url) 
      }
    : {};
  

  const res = await axiosServices.get(url, { 
    ...config, 
    headers: { ...config?.headers, ...headers } 
  });
  
  return res.data;
};


export const fetcherPost = async (args, options = { hmac: false }) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];
  
  // Generate HMAC if enabled
  const headers = options.hmac
    ? { 
        'X-Signature': generateHMAC('POST', url, JSON.stringify(data)) 
      }
    : {};
  
  const res = await axiosServices.post(url, data, { 
    ...config, 
    headers: { ...config?.headers, ...headers } 
  });
  
  return res.data;
};

export const fetcherPut = async (args, options = { hmac: false }) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];
  
  // Generate HMAC if enabled
  const headers = options.hmac
    ? { 
        'X-Signature': generateHMAC('PUT', url, JSON.stringify(data)) 
      }
    : {};
  
  const res = await axiosServices.put(url, data, { 
    ...config, 
    headers: { ...config?.headers, ...headers } 
  });
  
  return res.data;
};

export const fetcherDelete = async (args, options = { hmac: false }) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  
  // Generate HMAC if enabled
  const headers = options.hmac
    ? { 
        'X-Signature': generateHMAC('DELETE', url) 
      }
    : {};
  
  const res = await axiosServices.delete(url, { 
    ...config, 
    headers: { ...config?.headers, ...headers } 
  });
  
  return res.data;
};


export default axiosServices;
