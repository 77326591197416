import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Badge,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import { ThemeMode } from "config";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "utils/axios";

// assets
import avatar2 from "assets/images/users/avatar-2.png";
import { MailOutlined, CheckCircleOutlined } from "@ant-design/icons";
import ReactTimeAgo from "react-timeago";

// sx styles
const avatarSX = {
  width: 48,
  height: 48,
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

// ==============================|| HEADER CONTENT - MESSAGES ||============================== //

const fetchMessages = async (page) => {
  const response = await fetcher(`/chat?page=${page}`);
  return response;
};

const Message = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const [read, setRead] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 1, // initial page index
    pageSize: 10, // default page size
  });

  //console.log({setPagination})

  const { isLoading: usersLoading, data } = useQuery({
    queryKey: ["messages", pagination.pageIndex],
    queryFn: () => fetchMessages(pagination.pageIndex),
    keepPreviousData: true,
  });



  const users = data?.chats;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (!usersLoading) {
      setRead(users?.length || null);
    }
    // eslint-disable-next-line
  }, [usersLoading]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleNextPage = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  };
  

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "background.default" : "grey.100";

    //console.log({users})

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : "transparent",
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <MailOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          maxHeight: "calc(100vh - 250px)",
        }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -60 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Message"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => setRead(0)}
                          >
                            <CheckCircleOutlined
                              style={{ fontSize: "1.15rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      "& .MuiListItemButton-root": {
                        py: 1.5,
                        "& .MuiAvatar-root": avatarSX,
                        "& .MuiListItemSecondaryAction-root": {
                          ...actionSX,
                          position: "relative",
                        },
                      },
                    }}
                  >
                    {users &&
                      users.map((record) => {
                        const { Id, updatedAt, lastMessage, status } = record;
                        // setRead(count);
                        return (
                          <>
                            <ListItemButton key={Id} selected={status > 0}>
                              <ListItemAvatar>
                                <Avatar alt="profile user" src={avatar2} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h6">
                                    New message from <b>{lastMessage.sender_name}</b>
                                  </Typography>
                                }
                                secondary={<ReactTimeAgo date={updatedAt} />}
                              />
                              <ListItemSecondaryAction>
                                <Typography variant="caption" noWrap>
                                  <ReactTimeAgo date={updatedAt} />
                                </Typography>
                              </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                          </>
                        );
                      })}

                    <ListItemButton sx={{ textAlign: "center" }} onClick={handleNextPage}>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Message;
