// project import
import applications from './applications';
import ads from './ads';
// import formsTables from './forms-tables';
// import samplePage from './sample-page';
// import other from './other';
import pages from './pages';
// import chartsMap from './charts-map';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [widget, applications, formsTables, chartsMap, samplePage, pages, other]
  items: [ 
    // chartsMap, 
    applications , 
    // samplePage,
    ads,
    pages,
    // other  
  ]

};

export default menuItems;
