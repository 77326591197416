// ProtectedRoute.js
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const ProtectedRoute = () => {
  const { user } = useAuth(); // Replace with your actual user context

  // Check if user is authenticated; if not, redirect to login page
  return user?.isValidCustomer ? <Outlet /> : <Navigate to="/subscription" />;
};

export default ProtectedRoute;
