// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserOutlined, IssuesCloseOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined } from '@ant-design/icons';

// icons
const icons = { UserOutlined, IssuesCloseOutlined, DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages = {
  id: 'group-pages',
  title: <FormattedMessage id="pages" />,
  type: 'group',
  children: [
    {
      id: 'support',
      title: <FormattedMessage id="support" />,
      type: 'item',
      url: '/support',
      paid: true,
      helpTitle: 'Support',
      icon: icons.IssuesCloseOutlined
    }, 
    {
      id: 'feedback',
      title: <FormattedMessage id="feedback" />,
      type: 'item',
      url: '/feedback',
      helpTitle: 'Feedback & Updates',
      icon: icons.IssuesCloseOutlined
    },        
    // {
    //   id: 'subscription',
    //   title: <FormattedMessage id="sub" />,
    //   type: 'item',
    //   helpTitle: 'Subscription',
    //   url: '/subscription',
    //   icon: icons.DollarOutlined
    // }
  ]
};

export default pages;
