// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import { NavActionType } from 'config';
// import { handlerCustomerDialog } from 'api/customer';
// import { handlerCareerDialog } from 'api/career';
// import { useNavigate } from 'react-router';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  PlusOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  EnvironmentOutlined,
  LayoutOutlined,
  LinkOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  PlusOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  EnvironmentOutlined,
  LayoutOutlined,
  LinkOutlined
};

// const navigation = useNavigate();
// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'chat',
      title: <FormattedMessage id="chat" />,
      type: 'item',
      url: '/chat',
      icon: icons.MessageOutlined,
      paid: false,
      helpTitle: 'Messenger',
      breadcrumbs: false
    },
    {
      id: 'calendar',
      title: <FormattedMessage id="calendar" />,
      type: 'item',
      paid: false,
      url: '/calendar',
      helpTitle: 'Messenger',
      icon: icons.CalendarOutlined
    },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      type: 'collapse',
      icon: icons.CustomerServiceOutlined,
      helpTitle: 'Manage Customers',
      isAdmin: true,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/customer',
          actions: [
            {
              type: NavActionType.FUNCTION,
              label: 'Add Customer',
              icon: icons.PlusOutlined
            }
          ]
        },
        {
          id: 'customer-card',
          title: <FormattedMessage id="cards" />,
          type: 'item',
          url: '/customer/customer-card'
        }
      ]
    },
    {
      id: 'career',
      title: <FormattedMessage id="career" />,
      url: '/careers', 
      helpTitle: 'Job & Applicants',
      type: 'item',
      icon: icons.ContactsOutlined,
    },  
  ]
};

export default applications;
