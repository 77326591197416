import PropTypes from "prop-types";
import {createContext, useEffect, useReducer} from "react";
import {LOGIN, LOGOUT} from "contexts/auth-reducer/actions";
import authReducer from "contexts/auth-reducer/auth";
import Loader from "components/Loader";
import {fetcher, fetcherPost, getCookie, setCookie, clearTokens} from "utils/axios";

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    isInitialized: false,
    isCustomer: false,
    user: null,
};

const JWTContext = createContext(null);

export const JWTProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const accessToken = getCookie("accessToken");
                if (accessToken) {
                    const {user} = await fetcher("/account/me", {hmac: true});

                    dispatch({
                        type: LOGIN,
                        payload: {
                            subscriptionId: user.subscriptionId ?? false,
                            isAdmin: user.isAdmin ?? false,
                            isLoggedIn: true,
                            user,
                        },
                    });
                } else {
                  clearTokens();
                    dispatch({
                        type: LOGOUT,
                    });
                }
            } catch (err) {
                console.error(err);
                clearTokens();
                dispatch({
                    type: LOGOUT,
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        try {
            const url = '/portal/login';
            const {serviceToken, refreshToken, user} = await fetcherPost([url, {email, password}], {hmac: true});

            // Store tokens and update session
            storeTokens(serviceToken, refreshToken, user);

            dispatch({
                type: LOGIN,
                payload: {
                    isAdmin: user.isAdmin ?? false,
                    isLoggedIn: true,
                    user,
                },
            });
        } catch (error) {
            throw error;
        }
    };

    const register = async (email, password, confirmPassword, companyName, firstName, lastName) => {
      try {
          const url = '/portal/register';
          
          // Make the registration API call
          await fetcherPost(
              [url, { email, password, confirmPassword, companyName, firstName, lastName }],
              { hmac: true }
          );
          
          // Automatically log the user in after successful registration
          await login(email, password);
      } catch (error) {  
          // Provide a more specific error message if needed
          throw new Error(error?.message || "Registration failed. Please try again.");
      }
  };
  

    const accessCode = async (code) => {
        await fetcherPost(["/portal/access", {code}], {hmac: true});
    };

    const logout = () => {
        clearTokens();
        dispatch({type: LOGOUT});
    };

    const resetPassword = async () => {};
    const updateProfile = () => {};

    if (!state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{...state, login, logout, register, resetPassword, updateProfile, accessCode}}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node,
};

export default JWTContext;

const storeTokens = (serviceToken, refreshToken, user) => {
    // Using setCookie utility to store tokens
    setCookie("accessToken", serviceToken, {path: "/", secure: true, sameSite: "Strict", maxAge: 3600}); // 30 days expiry
    setCookie("refreshToken", refreshToken, {path: "/", secure: true, sameSite: "Strict", maxAge: 2592000}); // 30 days expiry
    setCookie("user", user, {path: "/", secure: true, sameSite: "Strict", maxAge: 2592000});
};
