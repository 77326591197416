import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import ProtectedRoute from './ProtectedRoute';


// lazy load pages and components
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
const AppContactUS = Loadable(lazy(() => import('pages/extra-pages/contact-us')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

// calendar
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
const AppFeedback = Loadable(lazy(() => import('pages/apps/feedback')));

// const AppCareerCard = Loadable(lazy(() => import('pages/apps/career/card')));
const AppCareerList = Loadable(lazy(() => import('pages/apps/career/list')));
const AppCareerAdd = Loadable(lazy(() => import('sections/apps/career/FormAdd')));
const AppCareerEdit = Loadable(lazy(() => import('sections/apps/career/Edit')));

// Teams
// const AppTeamList = Loadable(lazy(() => import('pages/apps/team/list')));
// const AppTeamAdd = Loadable(lazy(() => import('sections/apps/team/FormAdd')));
// const AppTeamEdit = Loadable(lazy(() => import('sections/apps/team/Edit')));

// Direct Message
const AppDirectMessageList = Loadable(lazy(() => import('pages/apps/dm/list')));
const AppDirectMessageAdd = Loadable(lazy(() => import('sections/apps/dm/FormAdd')));
const AppDirectMessageEdit = Loadable(lazy(() => import('sections/apps/dm/Edit')));

// Event Message
const AppEventList = Loadable(lazy(() => import('pages/apps/event/list')));
const AppEventAdd = Loadable(lazy(() => import('sections/apps/event/FormAdd')));
const AppEventEdit = Loadable(lazy(() => import('sections/apps/event/Edit')));

// Mobile Ads
const AppMobileAdList = Loadable(lazy(() => import('pages/apps/mobile/list')));
const AppMobileAdAdd = Loadable(lazy(() => import('sections/apps/mobile/FormAdd')));
const AppMobileAdEdit = Loadable(lazy(() => import('sections/apps/mobile/Edit')));

// Support
const AppSupportList = Loadable(lazy(() => import('pages/apps/support/list')));
const AppSupportAdd = Loadable(lazy(() => import('sections/apps/support/FormAdd')));
const AppSupportEdit = Loadable(lazy(() => import('sections/apps/support/Edit')));


// ==============================|| MAIN ROUTING ||============================== //

const AppKanban = Loadable(lazy(() => import('pages/apps/kanban')));
const AppKanbanBacklogs = Loadable(lazy(() => import('sections/apps/kanban/Backlogs')));
const AppKanbanBoard = Loadable(lazy(() => import('sections/apps/kanban/Board')));

const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const PaymentPage = Loadable(lazy(() => import('pages/extra-pages/payment')));

const AppChat = Loadable(lazy(() => import('pages/apps/chat')));
// const AppNewChat = Loadable(lazy(() => import('pages/apps/newchat')));

const MainRoutes = {
  path: '/',
  element: <DashboardLayout />,
  children: [
    {
      path: 'chat',
      element: <AppChat />
    },    
    {
      path: 'subscription',
      children: [
        {
          index: true,
          element: <PricingPage />
        }
      ]
    }, 
    {
      path: 'plan/:id',
      element: <PaymentPage />
    },       
    {
      path: 'dashboard',
      children: [
        {
          index: true,
          element: <DashboardAnalytics />
        }
      ]
    },
    {
      path: 'calendar',
      element: <AppCalendar />
    },    
    {
      path: 'contact',
      element: <AppContactUS />
    },
    {
      path: 'careers',
      children: [
        {
          index: true,
          element: <AppCareerList />
        },
        {
          path: 'new',
          element: <AppCareerAdd />
        },
        {
          path: ':id',
          element: <AppCareerEdit />
        }
      ]
    },    
    // {
    //   path: 'team',
    //   element: <ProtectedRoute />, // Wrap protected routes here
    //   children: [
    //     {
    //       index: true,
    //       element: <AppTeamList />
    //     },
    //     {
    //       path: 'new',
    //       element: <AppTeamAdd />
    //     },
    //     {
    //       path: ':id',
    //       element: <AppTeamEdit />
    //     }
    //   ]
    // }, 
    {
      path: '/ads/directmessage',
      element: <ProtectedRoute />, // Wrap protected routes here
      children: [
        {
          index: true,
          element: <AppDirectMessageList />
        },
        {
          path: 'new',
          element: <AppDirectMessageAdd />
        },
        {
          path: ':id',
          element: <AppDirectMessageEdit />
        }
      ]
    }, 
    {
      path: '/ads/mobile',
      element: <ProtectedRoute />, // Wrap protected routes here
      children: [
        {
          index: true,
          element: <AppMobileAdList />
        },
        {
          path: 'new',
          element: <AppMobileAdAdd />
        },
        {
          path: ':id',
          element: <AppMobileAdEdit />
        }
      ]
    },  
    {
      path: '/ads/event',
      children: [
        {
          index: true,
          element: <AppEventList />
        },
        {
          path: 'new',
          element: <AppEventAdd />
        },
        {
          path: ':id',
          element: <AppEventEdit />
        }
      ]
    },    
    {
      path: '/support',
      element: <ProtectedRoute />, // Wrap protected routes here
      children: [
        {
          index: true,
          element: <AppSupportList />
        },
        {
          path: 'new',
          element: <AppSupportAdd />
        },
        {
          path: ':id',
          element: <AppSupportEdit />
        }
      ]
    },  
    {
      path: 'feedback',
      children: [
        {
          index: true,
          element: <AppFeedback />
        }
      ]
    },                      
    {
      path: 'kanban',
      element: <AppKanban />,
      children: [
        {
          path: 'backlogs',
          element: <AppKanbanBacklogs />
        },
        {
          path: 'board',
          element: <AppKanbanBoard />
        }
      ]
    },    
    {
      path: '*',
      element: <MaintenanceError />
    }
  ]
};

export default MainRoutes;
