import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
// import ComponentsRoutes from './ComponentsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    // ComponentsRoutes,
    LoginRoutes,
    MainRoutes
  ]);

export default router;
