import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI components
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// Ant Design icons
import { UserOutlined, WalletOutlined } from '@ant-design/icons';
import { CommentOutlined, LockOutlined, QuestionCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';

// ==============================|| HEADER PROFILE & SETTINGS TAB ||============================== //

const ProfileSettingsTab = ({ onCloseMenu }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    // onCloseMenu(); // Ensure this receives the event properly
    if (route) {
      navigate(route);
    }
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* Profile Items */}
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(0, '/billing', event)}
      >        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton>

      {/* Settings Items */}
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(1, '/support', event)}
      >        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(event) => handleListItemClick(2, '/account', event)}
      >        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Account Settings" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event) => handleListItemClick(3, '/privacy', event)}
      >        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Privacy Center" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) => handleListItemClick(4, '/feedback', event)}
      >        <ListItemIcon>
          <CommentOutlined />
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 5}
        onClick={(event) => handleListItemClick(5, '/history', event)}
      >        <ListItemIcon>
          <UnorderedListOutlined />
        </ListItemIcon>
        <ListItemText primary="History" />
      </ListItemButton>
    </List>
  );
};

ProfileSettingsTab.propTypes = {
  onCloseMenu: PropTypes.func.isRequired // Prop for closing the menu
};

export default ProfileSettingsTab;
