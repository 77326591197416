// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LineChartOutlined, IdcardOutlined, DatabaseOutlined, LayoutOutlined } from '@ant-design/icons';

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  LayoutOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget = {
  id: 'group-widget',
  title: <FormattedMessage id="ads" />,
  icon: icons.IdcardOutlined,
  type: 'group',
  paid: true,
  children: [
    {
      id: 'statistics',
      title: <FormattedMessage id="dm" />,
      type: 'item',
      url: '/ads/directmessage',
      paid: true,
      helpTitle: 'Direct Message Ads',
      icon: icons.IdcardOutlined
    },
    {
      id: 'data',
      title: <FormattedMessage id="mads" />,
      type: 'item',
      url: '/ads/mobile',
      helpTitle: 'Mobile Ads',
      paid: true,
      icon: icons.DatabaseOutlined
    },
    {
      id: 'events',
      title: <FormattedMessage id="events" />,
      type: 'item',
      url: '/ads/event',
      helpTitle: 'Manage Events',
      paid: true,
      icon: icons.LayoutOutlined
    },  
    // {
    //   id: 'chart',
    //   title: <FormattedMessage id="analytics" />,
    //   type: 'item',
    //   helpTitle: 'Ad Analytics',
    //   url: '/ads/analytics',
    //   paid: true,
    //   icon: icons.LineChartOutlined
    // },      
  ]
};

export default widget;
