// queryClient.js
import { QueryClient } from '@tanstack/react-query';
import { fetcher, fetcherPost, fetcherPut, fetcherDelete  } from 'utils/axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        //console.log({queryKey})
        const [url, method = 'get', payload = {}] = queryKey;
        
        switch (method.toLowerCase()) {
          case 'get':
            return await fetcher(url);
          case 'post':
            return await fetcherPost(url, payload);
          case 'put':
            return await fetcherPut(url, payload);
          case 'delete':
            return await fetcherDelete(url);
          default:
            throw new Error(`Unsupported method: ${method}`);
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  },
});

export default queryClient;

